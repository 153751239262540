<template>
  <v-dialog
    v-model="confirm"
    max-width="1600"
    scrollable
    @click:outside="$emit('closeConfirm')"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent"> Detalhes do Pedido </v-card-title>

      <v-card-text class="px-5 py-6">
        <div>Tem certeza de que deseja {{ message }}?</div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn class="xbColor--text" @click="$emit('closeConfirm')">
          Cancelar
        </v-btn>
        <v-btn class="xbColor--text" @click="$emit('confirmar')"> Sim </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",

  data() {
    return {
      loading: false,
    };
  },

  props: {
    confirm: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.buttons {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  gap: 0.5rem;
}

@media (max-width: 400px) {
  .buttons {
    flex-direction: column;
  }
}
</style>
