<template>
  <v-dialog
    v-model="dialog"
    max-width="1600"
    scrollable
    @click:outside="$emit('closeDialog')"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent"> Detalhes do Pedido </v-card-title>

      <v-card-text class="px-5 py-6">
        <div class="d-flex flex-row justify-space-between mb-6">
          <h2>{{ pedido?.cliente?.nome }}</h2>
          <h3 v-if="pedido?.assento">Assento {{ pedido?.assento }}</h3>
        </div>

        <template v-for="(item, index) in pedido?.vendas_itens">
          <div :key="item.id">
            <div class="d-flex flex-row">
              <v-img
                :src="item?.produto?.imagem"
                max-height="4rem"
                max-width="4rem"
                class="height-4 width-4"
              />
              <div class="mx-4 d-flex flex-column flex-grow-1 justify-center">
                <h4
                  :style="
                    item?.status === 0 ? '' : 'text-decoration: line-through'
                  "
                  class="black--text"
                >
                  {{ item?.produto?.descricao }}
                </h4>
                <h5 class="mb-0">R$ {{ item?.valor | currency }}</h5>
              </div>
              <div class="d-flex flex-column justify-end black--text">
                <v-btn
                  v-if="perfil === 'ADMIN' || perfil === 'STAFF'"
                  icon
                  @click="mudarDisponibilidadeItem(index)"
                  ><v-icon>{{
                    item?.status === 0 ? "mdi-close" : "mdi-restore"
                  }}</v-icon></v-btn
                >
                <h4 style="white-space: nowrap">{{ item?.qtd }} un.</h4>
              </div>
            </div>
            <v-divider class="my-4" />
          </div>
        </template>

        <h3 class="text-center" v-if="!(pedido?.vendas_itens?.length > 0)">
          Não há nenhum item adicionado
        </h3>

        <div>
          <h4 class="text-right">
            Valor:
            <span class="black--text"> R$ {{ somaItens | currency }}</span>
          </h4>
          <h4 class="text-right">
            Descontos:
            <span class="black--text">
              R$ {{ pedido?.desconto | currency }}</span
            >
          </h4>
          <h4 class="text-right">
            Total:
            <span class="black--text"> R$ {{ valorTotal | currency }}</span>
          </h4>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions
        class="d-flex justify-space-between"
        v-if="perfil === 'ADMIN' || perfil === 'MERCADO'"
      >
        <v-btn class="xbColor--text" @click="$emit('closeDialog')">
          Fechar
        </v-btn>
        <div class="buttons">
          <v-btn
            v-if="pedido?.status === 0 && perfil === 'ADMIN'"
            class="xbColor--text"
            @click="openConfirmDialog('cancelar o pedido', cancelarPedido)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="pedido?.status === 0"
            class="white--text"
            color="xbColor"
            @click="openConfirmDialog('confirmar a entrega', entregarPedido)"
          >
            Entregue
          </v-btn>
          <v-btn
            v-else-if="perfil === 'ADMIN'"
            class="white--text"
            color="xbColor"
            @click="openConfirmDialog('reabrir o pedido', reabrirPedido)"
          >
            Reabrir
          </v-btn>
        </div>
      </v-card-actions>

      <ConfirmDialog
        :confirm="confirm"
        :message="message"
        @closeConfirm="cancelarAcao"
        @confirmar="action"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { updateItemPedido, updatePedido } from "@/api/admin/loja";
import { inputRequired } from "@/plugins/utils.js";
import ConfirmDialog from "./ConfirmDialog.vue";
import { mapState } from "vuex";

export default {
  name: "MoreInfoPedido",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    pedido: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      salvando: false,
      indexSalvando: null,
      form: {},
      formRules: [inputRequired],
      validForm: true,
      confirm: false,
      message: "",
      action: () => {},
    };
  },
  computed: {
    somaItens() {
      let soma = 0;
      this.pedido?.vendas_itens?.forEach((item) => {
        if (item.status === 0) soma += Number(item.valor);
      });
      return soma;
    },
    valorTotal() {
      const valor = this.somaItens - Number(this.pedido?.desconto);
      if (valor > 0) return valor;
      else return 0;
    },
    ...mapState("Usuario", {
      perfil: (state) => state.role,
    }),
  },
  methods: {
    openConfirmDialog(msg, acao) {
      this.message = msg;
      this.confirm = true;
      this.action = acao;
    },
    cancelarAcao() {
      this.message = "";
      this.confirm = false;
      this.action = () => {};
    },
    atualizarPedido(pedido, id) {
      pedido.vendas_itens.forEach(async (item) => {
        if (item.status === 1) {
          updateItemPedido(item, item.id).catch((e) => {
            console.log(e);
          });
          pedido.valor -= item.valor;
          pedido.valor_total -= item.valor;
        }
      });
      pedido.valor = this.somaItens.toString();
      pedido.valor_total = this.valorTotal.toString();
      updatePedido(pedido, id)
        .then(() => {
          this.$toast.success("Pedido atualizado com sucesso");
          this.loading = false;
          this.$emit("closeDialog");
          this.$emit("updateTable");
          this.cancelarAcao();
          this.update();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    entregarPedido() {
      this.loading = true;
      const pedidoEntregue = {
        ...this.pedido,
        status: 1,
      };
      this.atualizarPedido(pedidoEntregue, this.pedido?.id);
    },
    cancelarPedido() {
      this.loading = true;
      const pedidoEntregue = {
        ...this.pedido,
        status: -1,
      };
      this.atualizarPedido(pedidoEntregue, this.pedido.id);
    },
    reabrirPedido() {
      this.loading = true;
      const pedidoEntregue = {
        ...this.pedido,
        status: 0,
      };
      this.atualizarPedido(pedidoEntregue, this.pedido.id);
    },
    mudarDisponibilidadeItem(index) {
      if (this.pedido.vendas_itens[index].status === 0)
        this.pedido.vendas_itens[index].status = 1;
      else this.pedido.vendas_itens[index].status = 0;
    },
  },
  components: { ConfirmDialog },
};
</script>

<style scoped>
.buttons {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  gap: 0.5rem;
}

@media (max-width: 400px) {
  .buttons {
    flex-direction: column;
  }
}
</style>
